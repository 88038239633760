import React from 'react';
import PropTypes from 'prop-types';
import format from "date-fns/format";
import { WwaCheckbox } from 'common/components/WwaInputElements';
import { FormGroup, FormControl, FormLabel, Col, Row } from 'react-bootstrap';
import { MODAL_OPEN } from 'common/ApiPackage/ApiPackageDuck';
import UpsertApiPackage from 'Admin/ApiPackage/UpsertApiPackage';
import { safeLimit } from 'common/helpers/helpers';
import BusySpinner from "common/components/BusySpinner";
import { Alert, Button } from "react-bootstrap";
import deleteIcon from 'images/dashboard/watchlist-delete.svg'

import './SubscriptionSelector.css';

const formatDate = d => format(d, "YYYY/MM/DD HH:mm:ss");

const MAX_ADDITIONAL_ADDRESS_FIELDS = 18;

const LimitCheckBox = ({ id, checked, onClick, label, disabled }) => (
  <div className="wwa_checkbox_and_label">
    <WwaCheckbox id={id} name={id} onChange={onClick} checked={checked} disabled={disabled} />
    <span className="wwa_checkbox_label">{label || 'Unlimited'}</span>
  </div>
);
const NEW_PACKAGE = '-new';
export const NOT_API_ENABLED = '-notenabled';

const SubscriptionCheckboxes = ({
  subscription,
  setLimit,
  setUnlimited,
  setType,
  setApiPackage,
  setReactivateTrial,
  apiPackages,
  activateTrialCompany,
  companyId,
  isFetching,
  activateTrialError,
  activateTrialSuccess,
  addAddressRow,
  removeAddressRow,
  updateAddressRow
}) => (
  <div>
    <div className="SubscriptionSelector_SubscriptionCheckboxes">
      <div>
        <SubscrField
          label="Sites"
          idLimit="activeSiteLimit"
          value={subscription.activeSiteLimit}
          idCheckBox="activeSiteUnlimited"
          checked={subscription.activeSiteUnlimited}
          setLimit={setLimit}
          setUnlimited={setUnlimited}
        />

        <SubscrField
          label="Users"
          idLimit="personLimit"
          value={subscription.personLimit}
          idCheckBox="personUnlimited"
          checked={subscription.personUnlimited}
          setLimit={setLimit}
          setUnlimited={setUnlimited}
        />

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            Enable company
          </Col>

          <Col sm={3}>
            <FormControl plaintext readOnly defaultValue="" />
          </Col>

          <Col as={FormLabel} sm={4}>
            <LimitCheckBox
              id="active"
              label=" "
              checked={subscription.active}
              onClick={e => setUnlimited(e.target.id, e.target.checked)}
            />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            Snowtify
          </Col>

          <Col sm={3}>
            <FormControl plaintext readOnly defaultValue="" />
          </Col>

          <Col as={FormLabel} sm={4}>
            <LimitCheckBox
              id="callToAction"
              label=" "
              checked={subscription.callToAction}
              onClick={e => setUnlimited(e.target.id, e.target.checked)}
            />
          </Col>
        </FormGroup>

        {
          subscription.trialEnds && (
            <FormGroup as={Row}>
              <Col 
                as={FormLabel}
                sm={5}
                className="SubscriptionSelector_Label"
              >
                Trial Ends:
              </Col>
              <Col sm={3}>
                {formatDate(subscription.trialEnds)}
              </Col>
              <Col sm={4}>
                {activateTrialError && (
                  <Alert variant="danger">
                    {activateTrialError}
                  </Alert>
                )}
                {activateTrialSuccess && (
                  <Alert variant="success">
                    Company was activated successfully!
                  </Alert>
                )}

                {subscription.trialActivated 
                  ? <LimitCheckBox
                      id="reactivateTrial"
                      label="Reactivate Trial"
                      checked={subscription.reactivateTrial}
                      onClick={e => setReactivateTrial(e.target.checked)}
                      disabled={subscription.active}
                    />
                  : <Button
                      variant="primary"
                      onClick={() => activateTrialCompany(companyId)}
                      disabled={isFetching || !companyId}
                    >
                      Activate Trial Company
                      {isFetching && <BusySpinner inButton={true} />}
                    </Button>
                }

              </Col>
            </FormGroup>
          )
        }

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            Type
          </Col>

          <Col sm={3}>
            <FormControl plaintext readOnly defaultValue="" />
          </Col>

          <Col as={FormLabel} sm={4}>
            <LimitCheckBox
              id="winter"
              label="Winter"
              checked={subscription.types && subscription.types.includes('winter')}
              onClick={e => setType(e.target.id, e.target.checked)}
              disabled={subscription.types && 
                  subscription.types.length === 1 && 
                  subscription.types.includes('winter')
              }
            />
            <LimitCheckBox
              id="nonwinter"
              label="Non-Winter"
              checked={subscription.types && subscription.types.includes('nonwinter')}
              onClick={e => setType(e.target.id, e.target.checked)}
              disabled={subscription.types && 
                  subscription.types.length === 1 && 
                  subscription.types.includes('nonwinter')
              }
            />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            National Snow Maps
          </Col>

          <Col sm={3}>
            <FormControl plaintext readOnly defaultValue="" />
          </Col>

          <Col as={FormLabel} sm={4}>
            <LimitCheckBox
              id="nationalSnowMaps"
              label=" "
              checked={subscription.nationalSnowMaps}
              onClick={e => setUnlimited(e.target.id, e.target.checked)}
            />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            Additional Address fields
          </Col>

          <Col sm={6}>
            <Button
                variant="primary"
                onClick={() => addAddressRow(`address${((subscription.addressXfields||{}).name||[]).length+2}`)}
                disabled={isFetching || !companyId || ((subscription.addressXfields||{}).name||[]).length > MAX_ADDITIONAL_ADDRESS_FIELDS}
              >
                Add Additional Address Field
              </Button>
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col sm={11} 
            className="SubscriptionSelector_Label"
          >
          <div className="SubscriptionSelector_AddressXGrid">
            <div>Field</div>
            <div>Friendly Name</div>
            <div>Show in Report</div>
            <div>Group By</div>
            <div/>
          </div>
          <div className="SubscriptionSelector_AddressXGrid_Row">
            <div className="SubscriptionSelector_AddressXGrid">
            {!!subscription.addressXfields &&
                ((subscription.addressXfields||{}).name||[]).map((field, i) => (
                  <React.Fragment key={i}>
                  <div>{field}</div>
                  <FormControl
                    id={`${i}`}
                    value={subscription.addressXfields.friendlyName[i]}
                    placeholder={(subscription.addressXfields||{}).name[i]}
                    onChange={e => updateAddressRow(e.target.id, "friendlyName", e.target.value)}
                  />
                  <WwaCheckbox
                    id={`${i}`}
                    onChange={e => updateAddressRow(e.target.id, "showInReports", e.target.checked)}
                    disabled={isFetching | !companyId}
                    checked={subscription.addressXfields.showInReports[i]}  />
                  <WwaCheckbox
                    id={`${i}`}
                    onChange={e => updateAddressRow(e.target.id, "groupByFlag", e.target.checked)}
                    disabled={isFetching | !companyId}
                    checked={subscription.addressXfields.groupByFlag[i]}  />
                  <img src={deleteIcon} onClick={() => removeAddressRow(i)} alt={'delete'}/>
                  </React.Fragment>
                )
                )}
            </div>
          </div>
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={8}
            className="SubscriptionSelector_Label"
          >
            <FormControl plaintext readOnly defaultValue="Select an API Package or create a new one" />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Col
            as={FormLabel}
            sm={5}
            className="SubscriptionSelector_Label"
          >
            API Package
          </Col>

          <Col sm={6}>
            <FormControl
              as="select"
              placeholder="Select a package"
              id="apiPackage"
              value={subscription.apiPackageId || ""}
              onChange={e => setApiPackage(e.target.value)}
            >
              {apiPackages.map(ap => (
                <option key={ap.id} value={ap.id}>
                  {ap.name}
                </option>
              ))}
              <option value={'---'} disabled>
                ---
              </option>
              {subscription.apiPackageId ||
                <option value=''>(Not enabled for API)</option>
              }
              {subscription.apiPackageId &&
                <option value={NOT_API_ENABLED}>Remove current</option>
              }
              <option value={NEW_PACKAGE}>Add new Package</option>
            </FormControl>

            {apiPackages
              .filter(ap => ap.id === subscription.apiPackageId)
              .map(ap => (
                <div key={ap.id} style={{ marginTop: '8px' }}>
                  <FormControl plaintext readOnly defaultValue={`Daily limit: ${safeLimit(ap.dailyLimit)} API calls`} />
                  <FormControl plaintext readOnly defaultValue={`Rate limit: ${safeLimit(ap.minLimit)} API calls per minute`} />
                </div>
              ))}
          </Col>
        </FormGroup>
      </div>
    </div>
  </div>
);

const SubscrField = ({
  label,
  idLimit,
  value,
  idCheckBox,
  checked,
  setLimit,
  setTypes,
  setUnlimited
}) => (
  <FormGroup as={Row}>
    <Col
      as={FormLabel}
      sm={5}
      className="SubscriptionSelector_Label"
    >
      {label}
    </Col>

    <Col sm={3}>
      <FormControl
        id={idLimit}
        value={value}
        onChange={e => setLimit(e.target.id, e.target.value)}
        disabled={checked}
      />
    </Col>

    <Col as={FormLabel} sm={4}>
      <LimitCheckBox
        id={idCheckBox}
        checked={checked}
        onClick={e => setUnlimited(e.target.id, e.target.checked)}
      />
    </Col>
  </FormGroup>
);

class SubscriptionSelector extends React.Component {
  componentDidMount() {
    this.props.resetActivateTrial();
    if (this.props.subscription) {
      this.props.setAllValues(this.props.subscription);
    } else {
      this.props.reset();
    }
    this.props.fetchApiPackages();
    this.props.loadCompanyApiSubscription();
  }

  handleSelectApiPackage = packageName => {
    if (packageName !== NEW_PACKAGE) {
      this.props.setApiPackage(packageName);
    } else {
      this.props.addApiPackageOnTheFly(this.props.companyId)
    }
  };

  render() {
    const {
      subscription,
      setLimit,
      setUnlimited,
      setType,
      setReactivateTrial,
      apiPackages,
      packageModalState,
      closeUpsertModal,
      isFetching,
      companyId,
      activateTrialCompany,
      activateTrialError,
      activateTrialSuccess,
      addAddressRow,
      removeAddressRow,
      updateAddressRow
    } = this.props;
    return (
      <React.Fragment>
        <SubscriptionCheckboxes
          subscription={subscription}
          apiPackages={apiPackages}
          setLimit={setLimit}
          setUnlimited={setUnlimited}
          setType={setType}
          setReactivateTrial={setReactivateTrial}
          setApiPackage={this.handleSelectApiPackage}
          activateTrialCompany={activateTrialCompany}
          companyId={companyId}
          isFetching={isFetching}
          activateTrialError={activateTrialError}
          activateTrialSuccess={activateTrialSuccess}
          addAddressRow={addAddressRow}
          removeAddressRow={removeAddressRow}
          updateAddressRow={updateAddressRow}
        />
        <UpsertApiPackage
          modalOpen={packageModalState === MODAL_OPEN}
          closeModal={closeUpsertModal}
          submitting={isFetching}
          onSubmit={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

SubscriptionSelector.propTypes = {
  subscription: PropTypes.object
};

export default SubscriptionSelector;
