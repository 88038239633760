import immutable from 'object-path-immutable';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import xor from 'lodash/xor';

// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

export const SUBSCRIPTIONSELECTOR_SETNUMBER = 'SUBSCRIPTIONSELECTOR_SETNUMBER';
export const SUBSCRIPTIONSELECTOR_SETBOOL = 'SUBSCRIPTIONSELECTOR_SETBOOL';
export const SUBSCRIPTIONSELECTOR_SETARRAY = 'SUBSCRIPTIONSELECTOR_SETARRAY';
export const SUBSCRIPTIONSELECTOR_SETREACTIVATETRIAL = 'SUBSCRIPTIONSELECTOR_SETREACTIVATETRIAL';
export const SUBSCRIPTIONSELECTOR_RESET = 'SUBSCRIPTIONSELECTOR_RESET';
export const SUBSCRIPTIONSELECTOR_SET_ALLVALUES = 'SUBSCRIPTIONSELECTOR_SET_ALLVALUES';
export const SUBSCRIPTIONSELECTOR_SETAPIPACKAGE = 'SUBSCRIPTIONSELECTOR_SETAPIPACKAGE';
export const SUBSCRIPTIONSELECTOR_ADD_ADDRESSROW = 'SUBSCRIPTIONSELECTOR_ADD_ADDRESSROW';
export const SUBSCRIPTIONSELECTOR_REMOVE_ADDRESSROW = 'SUBSCRIPTIONSELECTOR_REMOVE_ADDRESSROW';
export const SUBSCRIPTIONSELECTOR_ADDRESSROW_UPDATE_ROW = 'SUBSCRIPTIONSELECTOR_ADDRESSROW_UPDATE_ROW';

// Reducer

export const initialState = {
  activeSiteLimit: 100,
  activeSiteUnlimited: false,
  personLimit: 10,
  personUnlimited: false,
  active: true,
  callToAction: false,
  nationalSnowMaps: false,
  addressXfields: {
    name: [], // array of "Address2, Address3", "Address4", etc...
    friendlyName: [], // friendly names for corresponding fields in addressX, must have same length as addressX
    showInReports: [], // array of true|false
  },
  types: []
};

// Action creators

export const setAllValues = (companyId, subscription) => ({
  type: SUBSCRIPTIONSELECTOR_SET_ALLVALUES,
  companyId, subscription
})

export const setLimit = (companyId, limit, value) => ({
  type: SUBSCRIPTIONSELECTOR_SETNUMBER,
  companyId, limit, value
})

export const setUnlimited = (companyId, limit, value) => ({
  type: SUBSCRIPTIONSELECTOR_SETBOOL,
  companyId, limit, value
})

export const setApiPackage = (companyId, str) => ({
  type: SUBSCRIPTIONSELECTOR_SETAPIPACKAGE,
  companyId, key: 'apiPackageId', str
})

export const setType = (companyId, companyType, value) => ({
  type: SUBSCRIPTIONSELECTOR_SETARRAY,
  companyId, companyType, value
})

export const setReactivateTrial = (companyId, value) => ({
  type: SUBSCRIPTIONSELECTOR_SETREACTIVATETRIAL,
  companyId, value
})

export const reset = (companyId) => ({
  type: SUBSCRIPTIONSELECTOR_RESET,
  companyId
})

export const addAddressRow = (companyId, name) => ({
  type: SUBSCRIPTIONSELECTOR_ADD_ADDRESSROW,
  companyId,
  name
})

export const removeAddressRow = (companyId, i) => ({
  type: SUBSCRIPTIONSELECTOR_REMOVE_ADDRESSROW,
  companyId,
  i
})

export const updateAddressRow = (companyId, i, fieldName, value) => ({
  type: SUBSCRIPTIONSELECTOR_ADDRESSROW_UPDATE_ROW,
  companyId,
  i, 
  fieldName, 
  value
});


// Reducer

const updateGroup = (slice = initialState, {limit, value}) => ({
  ...slice,
  [limit]: isNaN(value) ? slice[limit] : Number(value)
})

const updateGroupBool = (slice = initialState, {limit, value}) => ({
  ...slice,
  [limit]: !!value
})

const updateGroupStr = (slice = initialState, {key, str}) => ({
  ...slice,
  [key]: str
})

// handle updating of "AddressX" fields; the groupByFlag must only be checked once for all rows
const updateAddressRowState = (state, action) => {
  let newState = state;
  // clear all "groupByFlag" because only one row should have this flag set
  for (let n = 0; n < state[action.companyId].addressXfields.groupByFlag.length; n++){
    newState = immutable
      .set(newState, 
        [[action.companyId], "addressXfields", "groupByFlag", n],
        false
      );
  }

  return immutable
    .set(newState, 
      [[action.companyId], "addressXfields", action.fieldName, +action.i],
      action.value
    );
}

const subscriptionSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONSELECTOR_RESET: 
      return initialState;

    case SUBSCRIPTIONSELECTOR_SET_ALLVALUES: 
      return {
        ...state,
        [action.companyId]: {
          ...action.subscription,
          companyId: action.companyId
        }
      };

    case SUBSCRIPTIONSELECTOR_SETNUMBER:
      return {
        ...state,
        [action.companyId]: updateGroup(state[action.companyId], action)
      }
    case SUBSCRIPTIONSELECTOR_SETBOOL:
      return {
        ...state,
        [action.companyId]: updateGroupBool(state[action.companyId], action)
      }

    case SUBSCRIPTIONSELECTOR_SETARRAY:
      // add or remove 'winter'/'nonwinter' company type
      return immutable.set(state, [action.companyId, 'types'], 
        action.value 
          ? uniq(union(state[action.companyId].types, [action.companyType]))
          : xor(state[action.companyId].types, [action.companyType])
      );

    case SUBSCRIPTIONSELECTOR_SETREACTIVATETRIAL:
      return immutable.set(
        state,
        [action.companyId, "reactivateTrial"],
        action.value
      );

    case SUBSCRIPTIONSELECTOR_SETAPIPACKAGE:
      return {
        ...state,
        [action.companyId]: updateGroupStr(state[action.companyId], action)
      }

    case SUBSCRIPTIONSELECTOR_ADD_ADDRESSROW: 
      return immutable(state)
        .push([[action.companyId], "addressXfields", "name"], action.name)
        .push([[action.companyId], "addressXfields", "friendlyName"], "")
        .push([[action.companyId], "addressXfields", "showInReports"], false)
        .push([[action.companyId], "addressXfields", "groupByFlag"], false)
        .value();

    case SUBSCRIPTIONSELECTOR_REMOVE_ADDRESSROW:
      return immutable(state)
        .del([[action.companyId], "addressXfields", "name", action.i])
        .del([[action.companyId], "addressXfields", "friendlyName", action.i])
        .del([[action.companyId], "addressXfields", "showInReports", action.i])
        .del([[action.companyId], "addressXfields", "groupByFlag", action.i])
        .value();

    case SUBSCRIPTIONSELECTOR_ADDRESSROW_UPDATE_ROW:
      return updateAddressRowState(state, action);

    default:
      return state

  }
}

export default subscriptionSelectorReducer;


